.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;
}

.tags li {
  float: left; 
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  height: 15px;
  line-height: 15px;
  padding: 0 10px 0 11px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;

  font-size: 10px;
  margin: 5px;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  /* box-shadow: inset 0 1px rgba(0, 0, 0, 0.25); */
  content: '';
  height: 3px;
  left: 5px;
  position: absolute;
  width: 3px;
  top: 6px;
}

.tag::after {
  background: #fff;
  border-bottom: 7.5px solid transparent;
  border-left: 5px solid #eee;
  border-top: 7.5px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: $brand-color;
  color: white;
}

.tag:hover::after {
   border-left-color: $brand-color; 
}