/**
 * Site header
 */
 .site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.dropbtn {
  @include relative-font-size(1);
  
  background-color: $background-color;
  border: none;
  // border: 1px solid $grey-color-light;
  // border-radius: 5px;
  padding: 5px 5px;

  cursor: pointer;
}

.dropdown {
  position: relative;
  display: block;
  float: right;
  top: 10px;
  right: 5px;
  margin: 2px;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: $background-color;
  z-index: 1;
  border: 1px solid $grey-color-light;
  border-radius: 5px;
  font-size: smaller;
}

.dropdown-content a {
  color: $text-color;
  padding: 5px 10px;
  line-height: $base-line-height;
  text-align: left;
  // text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: $background-color;
}



.site-nav-wrapper {
  position: relative;
}

.site-nav {
  float: right;
  // line-height: $base-line-height * $base-font-size * 2.25;
  position: absolute;
  top: 10px;
  user-select: none;
  // right: $spacing-unit / 2;
  right: 5px;
  background-color: $background-color;
  border: 1px solid $grey-color-light;
  border-radius: 5px;

  input.nav-trigger {
    display: none;
  }

  label[for="nav-trigger"] {
    display: block;
    float: right;
    text-align: center;
    width: auto;
    height: auto;
    padding: 5px 10px;
    z-index: 2;
    cursor: pointer;
  }

  input ~ .trigger {
    clear: both;
    display: none;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }

  .page-link {
    color: $text-color;
    display: block;
    padding: 5px 10px;
    line-height: $base-line-height;
    text-align: left;
    &:not(:last-child) {
      margin-right: 0;
    }
  }
}

img.banner {
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 95%;
  display: block;
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
